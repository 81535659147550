import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Box, Tab, Tabs } from '@mui/material';

import { SocialPlatform } from '@/models/Enums';

import FacebookInsights from './social-platforms/facebook-insights';
import InstagramInsights from './social-platforms/instagram-insights';
import SocialOverviewInsights from './social-platforms/SocialOverviewInsights';
import TikTokInsights from './social-platforms/tiktok-insights';
import TwitterInsights from './social-platforms/twitter-insights';

const SocialsInsights = () => {
  const { t } = useTranslation();
  const [params, setSearchParams] = useSearchParams();
  const platform = params.get('platform');

  const [value, setValue] = useState<string>(platform || SocialPlatform.OVERVIEW);

  useEffect(() => {
    if (platform && Object.values(SocialPlatform).includes(platform as SocialPlatform)) {
      setValue(platform);
      setSearchParams({ tab: 'socials', platform: platform }, { replace: false });
    } else if (!platform) {
      setSearchParams({ tab: 'socials', platform: SocialPlatform.OVERVIEW }, { replace: false });
    }
  }, [setSearchParams, value, platform]);

  return (
    <>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          width: '100%',
          marginTop: '20px',
          marginBottom: '8px',
        }}
      >
        <Tabs
          value={value}
          onChange={(_, newValue) => setSearchParams({ tab: 'socials', platform: newValue })}
          aria-label="basic tabs example"
        >
          <Tab value={SocialPlatform.OVERVIEW} label={t('COMMON.OVERVIEW')} data-testid="insights-overview" />
          <Tab value={SocialPlatform.INSTAGRAM} label={t('BRAND.INSTAGRAM')} data-testid="insights-instagram" />
          <Tab value={SocialPlatform.FACEBOOK} label={t('BRAND.FACEBOOK')} data-testid="insights-facebook" />
          <Tab value={SocialPlatform.TIKTOK} label={t('BRAND.TIKTOK')} data-testid="insights-tiktok" />
          <Tab value={SocialPlatform.TWITTER} label={t('BRAND.TWITTER')} data-testid="insights-twitter" />
        </Tabs>
      </Box>
      {value === SocialPlatform.OVERVIEW && <SocialOverviewInsights />}
      {value === SocialPlatform.INSTAGRAM && <InstagramInsights />}
      {value === SocialPlatform.FACEBOOK && <FacebookInsights />}
      {value === SocialPlatform.TIKTOK && <TikTokInsights />}
      {value === SocialPlatform.TWITTER && <TwitterInsights />}
    </>
  );
};

export default SocialsInsights;
