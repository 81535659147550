import React from 'react';

import Subscription from '../../../../subscription/Subscription';
import BenchmarkInsightGauge from '../../../BenchmarkInsights/BenchmarkInsightGauge';
import FollowersChart from './FollowersChart';
import LikesChart from './LikesChart';

const TikTokInsights = () => {
  return (
    <>
      <BenchmarkInsightGauge platform="TikTok" />
      <div className="d-flex gap20 flex-item-wrap mb20 mt20">
        <FollowersChart />
        <LikesChart />
      </div>
      <Subscription type="banner-small" />
    </>
  );
};

export default TikTokInsights;
