import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Box, Tab, Tabs } from '@mui/material';

import { MusicPlatform } from '@/models/Enums';

import AmazonInsights from './music-platforms/AmazonInsights';
import AppleInsights from './music-platforms/AppleInsights';
import DeezerInsights from './music-platforms/DeezerInsights';
import MusicOverviewInsights from './music-platforms/MusicOverviewInsights';
import SoundcloudInsights from './music-platforms/SoundCloudInsights';
import SpotifyInsights from './music-platforms/SpotifyInsights';
import YoutubeInsights from './music-platforms/youtube-insights';

const MusicInsights = () => {
  const { t } = useTranslation();
  const [params, setSearchParams] = useSearchParams();
  const platform = params.get('platform');

  const [value, setValue] = useState<string>(platform || MusicPlatform.OVERVIEW);

  useEffect(() => {
    if (platform && Object.values(MusicPlatform).includes(platform as MusicPlatform)) {
      setValue(platform);
      return setSearchParams({ tab: 'music', platform: platform }, { replace: false });
    } else {
      setSearchParams({ tab: 'music', platform: MusicPlatform.OVERVIEW }, { replace: false });
    }
  }, [platform, setSearchParams, value]);

  return (
    <>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          width: '100%',
          marginTop: '20px',
          marginBottom: '8px',
        }}
      >
        <Tabs
          value={value}
          onChange={(_, newValue) => setSearchParams({ tab: 'music', platform: newValue })}
          aria-label="basic tabs example"
        >
          <Tab value={MusicPlatform.OVERVIEW} label={t('COMMON.OVERVIEW')} data-testid="insights-overview" />
          <Tab value={MusicPlatform.SPOTIFY} label={t('BRAND.SPOTIFY')} data-testid="insights-spotify" />
          <Tab value={MusicPlatform.YOUTUBE} label={t('BRAND.YOUTUBE')} data-testid="insights-youtube" />
          <Tab value={MusicPlatform.AMAZON} label={t('BRAND.AMAZON')} data-testid="insights-amazon" />
          <Tab value={MusicPlatform.APPLE} label={t('BRAND.APPLE')} data-testid="insights-apple" />
          <Tab value={MusicPlatform.SOUNDCLOUD} label={t('BRAND.SOUNDCLOUD')} data-testid="insights-soundcloud" />
          <Tab value={MusicPlatform.DEEZER} label={t('BRAND.DEEZER')} data-testid="insights-deezer" />
        </Tabs>
      </Box>
      {value === MusicPlatform.OVERVIEW && <MusicOverviewInsights />}
      {value === MusicPlatform.SPOTIFY && <SpotifyInsights />}
      {value === MusicPlatform.YOUTUBE && <YoutubeInsights />}
      {value === MusicPlatform.AMAZON && <AmazonInsights />}
      {value === MusicPlatform.APPLE && <AppleInsights />}
      {value === MusicPlatform.SOUNDCLOUD && <SoundcloudInsights />}
      {value === MusicPlatform.DEEZER && <DeezerInsights />}
    </>
  );
};

export default MusicInsights;
