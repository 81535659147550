import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { format } from 'date-fns';

import { DateTimes } from '@/constants/DateTimes';
import numberFormatter from '@/formatters/ShortNumberConverter';
import useLineChartData from '@/hooks/charts/useLineChartData';
import usePlatformArtistMetrics from '@/hooks/metrics/usePlatformArtistMetrics';
import { MetricsDataModel } from '@/models/Metrics';

import LineChartComponent from '../../../../charts/LineChart';
import DateTimeSelector from '../../../../utility/microcomponents/DateTimeSelector';

const SubscribersChart = () => {
  const { t } = useTranslation();

  const [selectedStartDate, setSelectedStartDate] = useState<string>();

  const { platformArtistMetrics } = usePlatformArtistMetrics({
    platform: 'youtube_channel',
    fromDate: selectedStartDate,
    toDate: DateTimes.yesterdayDate,
  });

  const subscribersDataMap = useMemo(() => {
    if (!platformArtistMetrics) return;
    return [
      {
        name: 'Subscribers',
        color: 'youtube',
        key: 'value',
        dataMap: new Map(
          platformArtistMetrics?.subscribers.map((item) => [format(new Date(item.date), 'yyyy-MM-dd'), item.value])
        ),
      },
    ];
  }, [platformArtistMetrics]);

  const subscribersChartData = useLineChartData<MetricsDataModel>({
    startDate: selectedStartDate,
    data: subscribersDataMap,
  });

  const handleDate = useCallback((date: string) => {
    setSelectedStartDate(date);
  }, []);

  return (
    <div className="card flex-w50p w100p-lg-down">
      <div className="d-flex jc-space-between mb8">
        <h3>{t('INSIGHTS-PAGE.COMMON.SUBSCRIBERS')}</h3>
        <DateTimeSelector dateOutput={handleDate} defaultRange="This year" />
      </div>
      <h4 className="mb20">
        {subscribersChartData
          ? numberFormatter(subscribersChartData.data[subscribersChartData.data.length - 1]?.value)
          : 0}
      </h4>
      <div>
        <LineChartComponent data={subscribersChartData} />
      </div>
    </div>
  );
};

export default SubscribersChart;
