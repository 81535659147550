import React from 'react';

import Subscription from '../../../../subscription/Subscription';
import LikesChart from './LikesChart';
import TalksChart from './TalksChart';

const FacebookInsights = () => {
  return (
    <>
      <div className="d-flex gap20 flex-item-wrap mb20">
        <TalksChart />
        <LikesChart />
      </div>
      <Subscription type="banner-small" />
    </>
  );
};

export default FacebookInsights;
